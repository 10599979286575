import React from "react";
import { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { CardGiftcard } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function Evouchers() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const isActiveLink = (path) => location.pathname === path;
    const activeColor = "#04f2fc";

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            {/* Main E-Vouchers Item */}
            <ListItem button onClick={handleClick} style={{ color: open ? activeColor : '#fff' }}>
                <div style={{ marginRight: '5%' }}>
                    <CardGiftcard />
                </div>
                <ListItemText primary="E-Vouchers" style={{ fontFamily: 'Poppins', color: open ? activeColor : '#fff' }} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Link style={{ textDecoration: 'none', color: isActiveLink("/open-evouchers") ? activeColor : '#fff' }} to="/open-evouchers">
                        <ListItem button className={classes.nested}>
                            <ListItemText primary="Open E-Vouchers" />
                        </ListItem>
                    </Link>
                    <Link style={{ textDecoration: 'none', color: isActiveLink("/closed-evouchers") ? activeColor : '#fff' }} to="/closed-evouchers">
                        <ListItem button className={classes.nested}>
                            <ListItemText primary="Closed E-Vouchers" />
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
        </div>
    );
}