import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, IconButton, Table, TableCell, TableHead, TableRow } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./invoice.css"
import PrintIcon from '@mui/icons-material/Print';
import jsondecode from "jwt-decode";
import html2pdf from 'html2pdf.js';
import { useTheme } from '@mui/material/styles';
import { currencyconverter, GetDefaultCurrency } from "../../../common/currencyconverter";

const moment = require('moment');

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "13px",
        fontWeight: "300",
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    p4: {
    padding:theme.spacing(1.25)
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign:"right"
    },
}));

const Receipt = (props) => {
    const navigate = useNavigate();

    const path = props?.path;
    const receiptNo = props?.receiptNo;


    const { receiptNumber, itemNumber } = useParams();

      // MEDIA QUERIES ???
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const [total, setTotal] = useState(0)
    const [businessInfo, setBusinessInfo] = useState({})
    const [receiptData, setReceiptData] = useState()

    const [invoicePayments, setInvoicePayments] = useState();
    const [invoiceBalance, setInvoiceBalance] = useState();

    const downloadPDF = () => {

        const input = document.getElementById('downloadable');
        html2pdf(input, {
            margin: 10,
            filename: 'receipt.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          }).from(input).save();
        
       
            
    }
    const viewPDF = () => {
        const input = document.getElementById("downloadable");
        html2pdf(input, {
            margin: 10,
            filename: 'receipt.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
          }).from(input).save();
    };
    const getReceiptDetails = async () => {
        try {
            const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_receipt_by_invoice_number?invoiceNumber=${path === "coa" ? receiptNo : itemNumber}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );
            if (invoiceDetailsResponse.status === 201) {
                const invoiceDetails = await invoiceDetailsResponse.json();
                console.log("receipt data ", invoiceDetails.data)
                setReceiptData(invoiceDetails.data);
                setBusinessInfo({
                    ...businessInfo,
                    businessName: invoiceDetails.data.fromCompanyName,
                    businessOwnerAddress: invoiceDetails.data.businessOwnerAddress,
                    country: invoiceDetails.data.fromBranchLocation,
                    businessOwnerEmail: invoiceDetails.data.fromBranchEmail,
                    businessOwnerPhone: invoiceDetails.data.fromPhoneNo,
                    houseNo: invoiceDetails?.data?.studentId,
                    unitType: invoiceDetails?.data?.unitType,
                    invoiceDatePaid: invoiceDetails?.data?.invoiceDatePaid,
                    businessLogo: invoiceDetails?.data?.businessLogo
                })
            }
        } catch (error) {
            console.error("error fetching invoice details", error.message);
        }
    };
    let localCurrency = localStorage.getItem('localCurrency')
    const localToken = localStorage.getItem("X-Authorization");

    const decoded = jsondecode(localToken)
    if (!localCurrency) {
        localCurrency = decoded?.localCurrency
    }
    if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
        localCurrency = 'KES'
    }

  // Number Format for Currency
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);
    const formatDate = (date) => {
        return moment(date).format("dddd, MMMM Do, YYYY");
    }

    const formatPaymentDate = (unFormatted) => {
        const date = new Date(unFormatted);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        const formattedDate = date.toLocaleString('en-US', options);
        const dateSuffix = function () {
            const date = new Date();
            const dateNumber = date.getDate();
            switch (dateNumber) {
                case 1:
                case 21:
                case 31:
                    return 'st';
                case 2:
                case 22:
                    return 'nd';
                case 3:
                case 23:
                    return 'rd';
                default:
                    return 'th';
            }
        };
        return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(0, 3)} ${formattedDate.slice(7)}`
    }

    const calculateTotal = () => {
        if (receiptData) {
            let calculatedTotal = 0;
            receiptData?.items?.forEach((product) => {
                calculatedTotal += product.productPrice * product.quantity;
            });
            setTotal(calculatedTotal);
        }
    }

    const back = () => {
        navigate("/receipts")
    }



    const getInvoicePayments = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${path === "coa" ? receiptNo : itemNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    'X-Authorization': localStorage.getItem('X-Authorization')
                },
            }
        );
        if (invoicePaymentDetailsResponse.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            console.log("Invoice payment data ", paymentDetailsData.data)
            let { invoicePayments, invoiceBalance } = paymentDetailsData.data
            console.log("ip", invoicePayments)
            setInvoicePayments(invoicePayments);
            setInvoiceBalance(invoiceBalance);
        }
    }


    useEffect(() => {
        getReceiptDetails().then(() => console.log("done receipt invoice data"))
        getInvoicePayments().then(() => console.log("payments fetched"))
    }, [receiptNo, itemNumber]);

    useEffect(() => {
        console.log("fff", businessInfo)
    }, [businessInfo]);


    useEffect(() => {
        calculateTotal();
    }, [receiptData]);

    //Get Business Type
    const businessCategory = localStorage.getItem('businessCategory')
    console.log(`Business category is`, businessCategory)
    return (
        <>
            <div style={matches ? { width: "100%",} : { width: "80%", marginLeft: "10%" }}>
                <div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ paddingTop: 15, display: "flex", alignItems:"center" }}>
                               {matches ? null : <span>
                                    <IconButton aria-label="back" onClick={() => path === 'coa' ? navigate("/customerreceipts") : back()}>
                                        <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                                    </IconButton>
                                </span> } 
                                <span style={ matches ? { "fontFamily": "Poppins", "fontSize": "20px", "fontWeight": "600", "textAlign": "left", "color": "#dc3545" } : { "fontFamily": "Poppins", "fontSize": "25px", "fontWeight": "600", "textAlign": "left", "color": "#dc3545" }}>Preview Receipt</span>
                            </div>
                            <div style={{ padding: 10, display: "flex", }}>


                                <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                                    <IconButton aria-label="download" onClick={downloadPDF}>
                                        <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                    </IconButton>
                                </span>
                                <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", marginLeft: "20px", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                                    <IconButton aria-label="download" onClick={viewPDF}>
                                        <PrintIcon sx={{ color: "#032541", fontSize: "25px" }} />
                                    </IconButton>
                                </span>

                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">

                            <div className={classes.p3} style={{ backgroundColor: `rgba(3,37,65,0.05)`, height: "150px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                                <div className={classes.p1} style={{ display: "flex", flexDirection: "column", }}>
                                    <span  style={matches ? { color: "black", fontSize: "25px", fontWeight: "700",  } : { color: "black", fontSize: "40px", fontWeight: "700", }}>PAYMENT RECEIPT</span>
                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "700", }}>Date:</span>
                                    <span style={{ fontSize: "14px" }}>{formatDate(receiptData?.createdAt)}</span>
                                </div>
                                <div>
                                  {matches ? null : <img src={`${baseUrl}/staticimages/logos/${businessInfo?.businessLogo}`} style={{ width: "150px", height: "150px", objectFit: "contain", marginRight:"200px"}} alt="businesslogo" /> }   
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                    <span style={{ color: "black", fontSize: "14px", fontWeight: "700", textAlign: "left" }}>Receipt No:</span>
                                    <span style={{ fontSize: "14px" }}>{receiptNumber}</span>
                                </div>
                            </div>

                            <div className={classes.p3} style={matches ? {display:"block"} : { display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                                <div style={{ display: "flex", flexDirection: "column", }}>
                                    <span style={{ fontSize: "18px", }}>From:</span>
                                    <span className={classes.address_text_common} style={{ fontWeight: "700" }}>{businessInfo?.businessName}</span>
                                    <span className={classes.address_text_common}>{businessInfo?.businessOwnerAddress}, {businessInfo?.country}</span>
                                    <span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
                                    <span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
                                </div>
                                <div className={classes.mt4} style={{ display: "flex", flexDirection: "column", textAlign: "right", }}>
                                    <span className={classes.address_text_common} style={{ fontWeight: "700" }}>Billed to:</span>
                                    <span className={classes.address_text_common}>{receiptData?.toName}</span>
                                    <span className={classes.address_text_common}>{receiptData?.toEmail}</span>
                                    <span className={classes.address_text_common}>{receiptData?.toPhoneNo}</span>
                                </div>
                            </div>
                            <br />
                            {
                                businessCategory === 'Rental' ?

                                    <div style={{ border: "dotted 1px", display: "flex", justifyContent: "flex-start", spacing: '10px', padding: "0.625rem" }}>
                                        <div>
                                            HOUSE NUMBER: <span style={{ fontWeight: "bold" }}>{businessInfo?.houseNo}</span>
                                        </div>
                                        <div style={{ marginLeft: '20px' }}>
                                            HOUSE TYPE: <span style={{ fontWeight: "bold", }}>{businessInfo?.unitType}</span>
                                        </div>
                                        <div style={{ marginLeft: '20px' }}>
                                            PERIOD: <span style={{ fontWeight: "bold" }}>{formatDate(businessInfo?.invoiceDatePaid)}</span>
                                        </div>

                                        <div style={{ marginLeft: '20px' }}>
                                            INVOICE NUMBER: <span style={{ fontWeight: "bold" }}>{itemNumber}</span>
                                        </div>
                                    </div> : businessCategory === 'School' ?
                                        <div style={{ border: "dotted 1px", display: "flex", justifyContent: "space-between", padding: "0.625rem" }}>
                                            <div>
                                                STUDENT ID: <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.studentId}</span>
                                            </div>
                                            <div>
                                                STUDENT NAME: <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.studentName}</span>
                                            </div>
                                            <div>
                                                GRADE:  <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.grade?.replace("Grade", "")}</span>
                                            </div>
                                            <div>
                                                TERM:  <span style={{
                                                    fontWeight: "bold"
                                                }}>{receiptData?.term?.replace("Term", "")}</span>
                                            </div>
                                        </div> : null
                            }

                            <br />
                        
                            <div>
                                <Table  sx={matches ? {width:"1%"} : {width:"100%"}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.textLeft}>
                                              {matches ? "Desc" : "Description"}  
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                Price ({GetDefaultCurrency()})
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>Qty</TableCell>
                                            <TableCell className={classes.textRight} sx={{ textAlign:"end"}}>
                                                Total ({GetDefaultCurrency()})
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {receiptData?.items?.map((product, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.textLeft}>
                                                <span className={classes.table_body}>
                                                    {product.productName}
                                                </span>
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                <span className={classes.table_body}> {product.productPrice} </span>
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                <span className={classes.table_body}>{product.quantity}</span>
                                            </TableCell>
                                            <TableCell className={classes.textRight} sx={{textAlign:"end"}}>
                                                <span className={classes.table_body}>{product.productPrice * product.quantity}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </div>


                            <div
                                className={classes.p3}
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                }}
                            >
                                <div
                                    className={classes.p4}
                                    style={{
                                        backgroundColor: "#f7f7f7",
                                        width: "288px",
                                        height: "130px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        className={classes.totals}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span>Subtotal</span>
                                        <span>{currencyconverter(total)} </span>
                                    </div>
                                    <div
                                        className={classes.totals}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span>Discount</span>
                                        <span>KES 0.00</span>
                                    </div>
                                    <div
                                        className={classes.totals}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span style={{ color: `${receiptData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>
                                            Total
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                color: `${receiptData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {currencyconverter(total)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div style={{ border: "dotted 1px" }}></div>
                            <br />
                            <div>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.textLeft}>
                                                Mode of Payment
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                Date
                                            </TableCell>
                                            <TableCell className={classes.textRight} sx={{textAlign:"end"}}>
                                                Amount
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {invoicePayments?.map((payment, index) => (
                                        <TableRow key={index}>
                                            <TableCell className={classes.textLeft}>
                                                <span className={classes.table_body}>
                                                    {payment.modeOfPayment}: {payment.ReferenceNumber}
                                                </span>
                                            </TableCell>
                                            <TableCell className={classes.textCenter}>
                                                <span className={classes.table_body}>{formatPaymentDate(payment.date)}</span>
                                            </TableCell>
                                            <TableCell className={classes.textRight} sx={{ textAlign:"end"}}>
                                                <span className={classes.table_body}>{currencyconverter(payment.amount)}</span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </div>
                            <br />
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                                    <span>Balance: {currencyconverter(invoiceBalance)}</span>
                                </div>
                            </div>
                            <br />
                            <div style={{ border: "dotted 1px" }}></div>
                            <br />

                            <div
                                className={classes.p2}
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <div
                                    className={classes.textCenter}
                                    style={{ fontSize: "10px", fontWeight: "300" }}
                                >
                                    This is a system-generated receipt is created without any
                                    alteration whatsoever.
                                    <br />
                                    Thank you for your business.
                                </div>
                            </div>
                            <div
                                className={classes.p2}
                                style={{
                                    backgroundColor: "#f7f7f7",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <span className={classes.footer_text}>
                                    Powered by <span style={{
                                        fontWeight: "700"
                                    }}>ZED</span>
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>
                                    info@zed.business
                                </span>
                                <span className={classes.footer_text}>.</span>
                                <span className={classes.footer_text}>v1.0.2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Receipt;
