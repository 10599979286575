import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { formatDate, getDate } from "../../../utils/common";
import PrintIcon from "@mui/icons-material/Print";
import GetAppIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import BackgroundImage from "../../../images/admin/background-image.svg";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import AdminNavbar from "../common/navbar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { formatDateReadeable, getDate } from "../../utils/dateFormatter";

const EvoucherReceipt = () => {
    const api_url = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("X-Authorization");
    const { paymentId } = useParams();
    const [paymentMode, setPaymentMode] = useState("");
    const [amount, setAmount] = useState("");
    const [date, setDate] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const localCurrency = localStorage.getItem("localCurrency");
    const [companyName, setCompanyName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyLocation, setCompanyLocation] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerLocation, setCustomerLocation] = useState("");
    const [referenceNo, setReferenceNo] = useState("");
    const [logoUrl, setLogo] = useState("");

    const breadcrumbs = [
        <Typography key="dashboard" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key="open-vouchers" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Open E-Vouchers
        </Typography>,
        <Typography key="preview-receipt" sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            Preview Receipt
        </Typography>
    ];

    // Function to fetch the voucher receipt data
    const handleVoucherReceipt = async () => {
        try {
            const response = await fetch(
                `${api_url}/api/v1/evoucher/getPaymentReceipt?receiptId=${paymentId}`,
                {
                    headers: {
                        "X-Authorization": token,
                    },
                }
            );
            const result = await response.json();

            if (result.Status === "SUCCESS") {
                // Set state with fetched data
                setPaymentMode(result?.data?.paymentChanel || result?.data?.transactionType);
                setAmount(result?.data?.totalAmount);
                setDate(result?.data?.dateCreated);
                setTransactionId(result?.data?.receiptNo);
                setCompanyName(result?.data?.fromCompanyName);
                setCompanyEmail(result?.data?.fromBranchEmail);
                setCompanyPhone(result?.data?.fromPhoneNo);
                setCompanyLocation(result?.data?.fromBranchLocation);
                setCustomerEmail(result?.data?.toEmail);
                setCustomerLocation(result?.data?.toAddress);
                setCustomerPhone(result?.data?.toPhoneNo);
                setCustomerName(result?.data?.toName);
                setReferenceNo(result?.data?.voucher?.requestReferenceId);
                setLogo(result?.data?.businessLogo);
            } else {
                console.error("Failed to fetch receipt:", result.Message);
            }
        } catch (error) {
            console.error("Error fetching receipt:", error);
        }
    };

    // Function to handle printing the receipt
    const handlePrint = () => {
        window.print();
    };

    // Function to handle downloading the receipt as a PDF
    const handleDownload = () => {
        const input = document.getElementById("receipt");
        html2canvas(input, { scale: 2, useCORS: true })
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/jpeg", 1.0);
                const pdf = new jsPDF("portrait", "mm", "a4");
                const imgWidth = 210;
                const margin = 10;
                const contentWidth = imgWidth - margin * 2;
                const imgHeight = (canvas.height * contentWidth) / canvas.width;

                pdf.addImage(imgData, "JPEG", margin, 10, contentWidth, imgHeight);
                pdf.save("receipt.pdf");
            })
            .catch((error) => {
                console.error("Error generating PDF:", error);
            });
    };

    // Fetch receipt data on component mount
    useEffect(() => {
        handleVoucherReceipt();
    }, []);

    return (
        <div style={{ display: 'flex', margin: "0 auto", background: "#fff", padding: "20px", backgroundSize: "cover", backgroundPosition: "center" }}>
            {/* <div style={{ width: '290px', flexShrink: 0 }}>
                <AdminSidebar />
            </div> */}
            <div style={{ flexGrow: 2, display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1, overflow: "hidden" }}>
                    {/* <AdminNavbar /> */}
                    <Box sx={{ flexGrow: 1, overflow: "hidden", marginLeft: '10px' }}>
                        <Box sx={{ marginTop: '10px' }}>
                            <Box sx={{display: 'flex'}}>
                                <ArrowBackIcon sx={{ color: "#000", marginRight: "8px" }} />
                                <Typography variant="h3" sx={{ color: '#032541', fontSize: '1.563rem', fontWeight: 700 }}>
                                    Preview Receipt
                                </Typography>
                            </Box>
                            <Breadcrumbs sx={{ marginTop: '20px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px", width: "90%" }}>
                            <IconButton onClick={handlePrint} title="Print" sx={{ background: "#f5f5f5" }}>
                                <PrintIcon />
                            </IconButton>
                            <IconButton onClick={handleDownload} title="Download" sx={{ background: "#f5f5f5" }}>
                                <GetAppIcon />
                            </IconButton>
                        </div>
                        <Box id="receipt" sx={{width: { xs: "150%", sm: "70%" },background: "#fff",padding: "30px",marginLeft: { xs: "0", sm: "20%", },}}>
                            <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", backgroundColor: '#f5f5f5' }}>
                                <div style={{ width: "60%" }}>
                                    <h1 style={{ fontSize: "30px", fontWeight: 800, textAlign: "left" }}>
                                        PAYMENT <br />
                                        RECEIPT
                                    </h1>
                                </div>
                                <div style={{ width: "70%", marginTop: "20px" }}>
                                    <img src={logoUrl} alt="Logo" style={{ width: "159px", height: "66px" }} />
                                </div>
                            </div>
                            <div style={{ marginTop: "4px", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width: "50%" }}>
                                    <p>Date: </p>
                                    <strong>{getDate(date)}</strong>
                                </div>
                                <div style={{ textAlign: "right", width: "50%" }}>
                                    <p>Receipt No:</p>
                                    <strong>{transactionId}</strong>
                                </div>
                            </div>

                            <section style={{ marginTop: "17px", padding: "1px", borderTop: "1px dashed #aaa", textAlign: "left", fontSize: "1px" }}></section>
                            <section style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width: "50%" }}>
                                    <p style={{ margin: "5px 0" }}><strong>From:</strong></p>
                                    <p style={{ margin: "5px 0" }}>{companyName}</p>
                                    <p style={{ margin: "5px 0" }}>
                                        {companyLocation}<br />
                                        {companyEmail}<br />
                                        {companyPhone}<br />
                                    </p>
                                </div>
                                <div style={{ textAlign: "right", width: "50%" }}>
                                    <p style={{ margin: "5px 0" }}><strong>To:</strong></p>
                                    <p style={{ margin: "5px 0" }}>{customerName}</p>
                                    <p style={{ margin: "5px 0" }}>
                                        {customerLocation}<br />
                                        {customerEmail}<br />
                                        {customerPhone}
                                    </p>
                                </div>
                            </section>

                            <table style={{ width: "100%", borderCollapse: "collapse", margin: "20px 0" }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: "10px", textAlign: "left" }}>Description</th>
                                        <th style={{ padding: "10px", textAlign: "right" }}>Total (KES)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "10px", textAlign: "left" }}>
                                            1. 1 e-voucher @ {localCurrency}{" "}
                                            {amount ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
                                        </td>
                                        <td style={{ padding: "10px", textAlign: "right" }}>
                                            {localCurrency}{" "}
                                            {amount ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f5f5f5" }}>
                                        <td></td>
                                        <td style={{ padding: "10px", textAlign: "right" }}>
                                            Total: {localCurrency}{" "}
                                            {amount ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <section style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ textAlign: "left", width: "50%" }}></div>
                                <div style={{ textAlign: "left", width: "30%", padding: "10px", background: "#f5f5f5", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ textAlign: "left", width: "50%", padding: "10px" }}>
                                        <p>Subtotal</p>
                                        <p>Discount</p>
                                        <p>Total</p>
                                    </div>
                                    <div style={{ textAlign: "right", width: "50%", padding: "10px 0 10px 10px" }}>
                                        <p>{localCurrency} {amount ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</p>
                                        <p>KES 0.00</p>
                                        <p>{localCurrency} {amount ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</p>
                                    </div>
                                </div>
                            </section>

                            <section style={{ marginTop: "17px", padding: "1px", borderTop: "1px dashed #aaa", textAlign: "left", fontSize: "1px" }}></section>

                            <table style={{ width: "100%", borderCollapse: "collapse", margin: "20px 0" }}>
                                <thead>
                                    <tr>
                                        <th style={{ padding: "10px", textAlign: "left" }}><strong>Mode of Payment</strong></th>
                                        <th style={{ padding: "10px", textAlign: "center" }}><strong>Date</strong></th>
                                        <th style={{ padding: "10px", textAlign: "right" }}><strong>Amount</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "10px", textAlign: "left" }}>{paymentMode}</td>
                                        <td style={{ padding: "10px", textAlign: "center" }}>{formatDateReadeable(date)}</td>
                                        <td style={{ padding: "10px", textAlign: "right" }}>
                                            {localCurrency}{" "}
                                            {amount ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "10px", textAlign: "left" }}>REF No: {referenceNo}</td>
                                        <td style={{ padding: "10px", textAlign: "left" }}></td>
                                        <td style={{ padding: "10px", textAlign: "right", color: "#17ae7b" }}>
                                            <strong>Balance KES 0.00</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <section style={{ marginTop: "17px", padding: "1px", borderTop: "1px dashed #aaa", textAlign: "left", fontSize: "1px" }}></section>
                            <div style={{ textAlign: "center", marginTop: "160px", marginLeft: "20%", marginRight: "20%" }}>
                                <p>
                                    Thank you for your payment to {companyName}. This
                                    system-generated receipt is created without any alterations
                                    whatsoever.
                                </p>
                            </div>

                            <footer style={{ textAlign: "center", marginTop: "30px", fontSize: "14px", color: "#888" }}>
                                <p style={{ margin: "5px 0" }}>
                                    Powered by <strong>ZED Payments Limited</strong> .
                                    info@zed.business . v1.0.2
                                </p>
                            </footer>
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default EvoucherReceipt;