import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import HttpComponent from '../MakeRequest';
import NotDoneImg from '../../../images/notdone-ft.svg';
import DoneImg from '../../../images/done-ft.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function BankDetailsFt() {
    const [formData, setFormData] = useState({
        companyCode: 'KE0010001',
        bankCode: '01',
        consumerSecret: '',
        consumerKey: '',
        accountName: '',
        accountNumber: '',
        bankName: 'KCB',
    });
    const [debitAccounts, setDebitAccounts] = useState([]);
    const [errorShow, setErrorShow] = useState({ state: false, message: '' });
    const [successShow, setSuccessShow] = useState({ state: false, message: '' });
    const [addAccountbuttonState, setButtonAddAccountState] = useState(true);
    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const [isBankOpen, setIsBankOpen] = useState(true);
    const [isftset, setifft] = useState(false);
    const [hideKcbKey, setHideKcbkey] = useState(false);
    const [hideKcbSecret, setHideKcbSecret] = useState(false);

    // get fund transfer config

    function getFundsTransferConfig() {
        try {
            HttpComponent({
                url: `/api/business/getfundstranferconfig`,
                method: 'GET',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setifft(data?.response?.isfundTransferSet);
                }
            });
        } catch (error) {}
    }
    useEffect(() => {
        getFundsTransferConfig();
    }, []);

    const getDebitAccounts = async () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/getfunds_transfer_config?accountType=KCB`,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    setDebitAccounts(data.response.data);
                }
            });
        } catch (error) {}
    };
    useEffect(() => {
        getDebitAccounts();
    }, []);

    const addAccount = async () => {
        if (formData.accountName !== '' || formData.accountNumber !== '' || formData.consumerKey !== '' || formData.consumerSecret !== '') {
            setButtonAddAccountState(true);
            return setErrorShow({ state: true, message: 'Missing fields' });
        } else {
            setButtonAddAccountState(false);
        }
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/addfunds_transfer_config`,
                body: formData,
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    setSuccessShow({ state: true, message: 'Account Added Successfully' });
                    setIsBankOpen(false);
                    getDebitAccounts();
                    setFormData({
                        ...formData,
                        companyCode: 'KE0010001',
                        bankCode: '01',
                        consumerSecret: '',
                        consumerKey: '',
                        accountName: '',
                        accountNumber: '',
                        bankName: 'KCB',
                    });
                }
            });
        } catch (error) {}
    };
    return (
        <Grid container flex={1} flexDirection={'column'} width={'100%'}>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert
                vertical="top"
                horizontal="right"
                onClose={() => setSuccessShow({ ...successShow, state: false })}
                open={successShow.state}
                message={successShow.message}
            />
            <Grid item width={'50%'} sx={{ border: isftset ? '1px solid #17ae7b' : '1px solid #bec5d1', paddingX: 2 }}>
                <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box component={'div'} width={'100%'} display={'flex'} py={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            Step 1: KCB Bank Debit Account Setup
                        </Typography>
                        <Box component={'img'} src={isftset ? DoneImg : NotDoneImg} />
                    </Box>
                </Box>
                {isBankOpen ? (
                    <Box component={'div'} display={'flex'} flexDirection={'column'} gap={2}>
                        <Box component={'div'} width={'100%'}>
                            <TextField id="outlined-basic" sx={{ width: '100%' }} name="accountName" value={formData.accountName} label="Name" variant="outlined" />
                        </Box>
                        <Box component={'div'} display={'flex'} width={'100%'} gap={2}>
                            <TextField id="outlined-basic" sx={{ width: '49%' }} name="companyCode" value={formData.companyCode} label="Company Code" variant="outlined" />
                            <TextField id="outlined-basic" sx={{ width: '48%' }} name="accountNumber" value={formData.accountNumber} label="Account Number" variant="outlined" />
                        </Box>
                        <Box component={'div'} width={'100%'}>
                            <FormControl variant="outlined" sx={{width:'100%'}}>
                                <InputLabel htmlFor="outlined-adornment-password-key">Consumer Key</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-key"
                                    type={hideKcbKey ? 'text' : 'password'}
                                    onChange={handleFormChange}
                                    value={formData.consumerKey}
                                    placeholder={'Consumer Key'}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    name="consumerKey"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setHideKcbkey(!hideKcbKey)} edge="end">
                                                {hideKcbKey ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Consumer Key"
                                />
                            </FormControl>
                        </Box>
                        <Box component={'div'} width={'100%'}>
                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                <InputLabel htmlFor="outlined-adornment-password-secret">Consumer Secret</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password-secret"
                                    type={hideKcbSecret ? 'text' : 'password'}
                                    onChange={handleFormChange}
                                    value={formData.consumerSecret}
                                    sx={{ width: '100%' }}
                                    placeholder={'Consumer Secret'}
                                    variant="outlined"
                                    name="consumerSecret"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setHideKcbSecret(!hideKcbSecret)} edge="end">
                                                {hideKcbSecret ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Consumer Secret"
                                />
                            </FormControl>
                        </Box>
                        <Box component={'div'} display={'flex'} justifyContent={'flex-end'} paddingY={2}>
                            <Button
                                sx={
                                    addAccountbuttonState
                                        ? { background: '#b4bcc4', color: '#ffffff', textTransform: 'inherit' }
                                        : { background: '#032541', color: '#ffffff', textTransform: 'inherit' }
                                }
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                ) : null}
            </Grid>
        </Grid>
    );
}
