import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, ListItemIcon, Menu, MenuItem, Select, Tab, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomSearchInput from "./CustomSearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomTable from "./CustomTable";
import { useSelector } from "react-redux";
import HttpComponent from "./MakeRequest";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import DeactivateIcon from "./Images/deactivate-icn.svg"
import CustomSelectField from "./CustomSelectField";
import ReplayIcon from "@mui/icons-material/Replay";
import ExportMenu from "./ExportMenu";
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';
import { Edit } from '@mui/icons-material';
import EditIcon from '../School/Images/edit-icn.svg';
import SuspendIcon from '../School/Images/decline.svg';
import CompleteIcon from '../School/Images/approve.svg';
import ExpelIcon from '../School/Images/logout-icon.png';
import TransferIcon from '../School/Images/component-854-1.png';
import WarningIcon from './Images/warning-remove-icn.svg'
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
// import DeactivateIcon from '../School/Images/deactivate-icn.svg'

let baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Students
    </Typography>
];


const SchoolListStudents = () => {
    const [tabValue, setTabValue] = useState("ACTIVE");
    const [gradeOptions, setGradeOptions] = useState([])
    const [courseOptions, setCourseOptions] = useState([]);
    const [streamsOptions, setStreamesOptions] = useState([]);
    const [stream, setStream] = useState('')
    const [grade, setGrade] = useState('')
    const [termOptions, setTermOptions] = useState([]);
    const [tableData, setTableData] = useState([])
    const [dataToShow, setDataToShow] = useState([]);
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    const [pageSize, setPageSize] = React.useState(10);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        count: 0,
        page: 1,
        pageSize: pageSize,
        // search : searchValue
    });
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [formData, setFormData] = useState({ search: "", grade: "", stream: "" })
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [gridLoading, setGridLoading] = useState(false)
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [selectedSchoolType, setSelectedSchoolType] = useState()
    const [searchValue, setSearchValue] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [allSchoolCategory, setAllSchoolCategory] = useState([])

    function GetType_school() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/config/school_classification`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setAllSchoolCategory(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetType_school()
    }, [])

    let urlParents = `/api/get_students_in_school?page=${pageState.page}&limit=${pageSize}&status=${tabValue}&searchValue=${searchValue}`
    if (selectedSchoolType) {
        urlParents = `/api/get_students_in_school?page=${pageState.page}&limit=${pageSize}&status=${tabValue}&classification=${selectedSchoolType}&searchValue=${searchValue}`
    }
    const fetchParents = () => {
        setGridLoading(true)
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpComponent({
            method: 'GET',
            url: urlParents, 
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here is parent  data", data.response.data);
            if (data.status === 200) {
                setTableData(data.response.data)
                setPageState({ ...pageState, isLoading: false, count: data?.response?.count, data: data.response.data })
                setGridLoading(false)
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
                setGridLoading(false)
            }
        }).catch((error) => {
            console.error(error.message);
            setGridLoading(false)
        })
    }
    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const GetStreams = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setStreamesOptions(data.data.map((itemStream) => {
                return { value: itemStream.streamName, label: itemStream.streamName }
            }
            ))
        }
    };

    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetGrades();
            GetStreams();
            GetStreamsTerms();
        }

    }, [schoolTypeId]);


    const setData = () => {
        let searchable = [...tableData]
        console.log(formData?.filter)
        if ((formData.search !== "") || (formData.grade !== '') || (formData.stream !== "")) {
            let result = searchable.filter(student =>
                (student?.firstName && student?.firstName?.toLowerCase()?.includes(formData.search)) ||
                (student?.lastName && student?.lastName?.toLowerCase()?.includes(formData.search)) ||
                (student?.parentName && student?.parentName?.toLowerCase()?.includes(formData.search)) ||
                (student?.grade && student.grade?.toLowerCase()?.includes(formData.grade?.toLowerCase())) ||
                (student?.stream && student.stream?.toLowerCase()?.includes(formData.stream?.toLowerCase())) ||
                (student?.boardingStatus && student?.boardingStatus.toLowerCase()?.includes(formData.search)) ||
                (student?.itemNumber && student?.itemNumber.toLowerCase()?.includes(formData.search))
            );
            console.log("searched result", result)
            setDataToShow(result);
        } else {
            setDataToShow(tableData)
        }
    }
    const rows = dataToShow?.filter((item) => {
        return grade === '' ? item : grade === 'ALL' ? item : item?.grade?.includes(grade)
    }).filter((item) => {
        return stream === '' ? item : stream === 'ALL' ? item : item?.stream?.toLowerCase()?.includes(stream?.toLowerCase())

    }).map((item, index) => {
        return {
            id: index,
            itemNumber: item?.itemNumber,
            studentName: item?.firstName + " " + item?.lastName,
            grade: schoolTypeName?.includes("University") ? item?.courseName : item?.grade,
            term: item?.term?.toLowerCase(),
            stream: item?.stream,
            boardingStatus: item?.boardingStatus,
            parentName: item?.parentName,
            parentPhone: item?.parentPhone,
            balance: item?.balance,
            pendingInvoices: item?.pendingInvoices,
            pendingAmount: item?.pendingAmount,
            dateOfAdmission: item.dateOfAdmission,
            invoiceCount: item.invoiceCount,
            parentId: item?.customerId,
            studentId: item?.studentId,
            parentEmail: item?.parentEmail,
            updatedAt: item?.updatedAt,
        }
    })


    useEffect(() => {
        setData()
    }, [formData.search, formData.grade, formData.stream, tableData, pageSize, pageState?.page]);

    useEffect(() => {
        fetchParents()
    }, [tabValue, selectedSchoolType, pageState?.page, pageSize, searchValue]);
    const navigate = useNavigate();

    const createButton = {
        // "width": "15.313rem",
        // "height": "2.813rem",
        "borderRadius": "5px",
        "border": "solid 1px #002543",
        "backgroundColor": "#fff",
        "color": "#032541",
        '&:hover': {
            backgroundColor: '#fff',
            color: '#032541'
        }
    }

    // fetch grades
    const handleMenuClick = (event, student) => {
        setAnchorEl(event.currentTarget);
        setSelectedStudent(student);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedStudent(null);
    };

    const headerStyles = {
        fontSize: "16px",
        fontWeight: "700",
        color: "#032541",
        textAlign: "left",
    };

    const cellStyles = {
        fontSize: "14px",
        fontWeight: "300",
        color: "#032541",
        textAlign: "left",
    };

    const activeColumns = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1,

            renderHeader: () => (
                <Typography style={headerStyles}>
                Admission No
                </Typography>
            ),

            renderCell: params => (
                <Typography   sx={{ color: "#032541", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student/${params?.row?.parentId}/${params.value}`)}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Student Name", field: "studentName", flex: 1,

            renderHeader: () => (
                <Typography style={headerStyles}>
                Student Name
                </Typography>
            ),
            renderCell: params => (
                <Typography style={cellStyles}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Grade", field: "grade", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                Grade
                </Typography>
            ),
            renderCell: params => (
            <Typography style={cellStyles}>
                {params.value}
            </Typography>
        ) },
        {
            headerName: "Stream", field: "stream", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                Stream
                </Typography>
            ),
            renderCell: params => (
            <Typography style={cellStyles}>
                {params.value}
            </Typography>
        )},
        {
            headerName: "Boarding Status", field: "boardingStatus", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                Boarding Status
                </Typography>
            ),
            renderCell: params => (
            <Typography style={cellStyles}>
                {params.value}
            </Typography>
        )},
        {
            headerName: "Parent Name", field: "parentName", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                    Parent Name
                </Typography>
            ),
            
            renderCell: (params) => {
                return (
                    <div>
                        <Typography style={cellStyles}>
                            <Link style={{ color: '#032541' }} to={`/school/parent/${params?.row?.parentId}`}>{params?.value}</Link>
                        </Typography>
                    </div>
                )
            }
        },
        {
            headerName: "Mobile Number", field: "parentPhone", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                Mobile Number
                </Typography>
            ),
            renderCell: params => (
            <Typography style={cellStyles}>
                {params.value}
            </Typography>
        ) },
        {
            headerName: "Pending Invoices", field: "pendingInvoices", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                Pending Invoices
                </Typography>
            ),
            renderCell: params => (
            <Typography style={cellStyles}>
                {params.value}
            </Typography>
        ) },
        {
            headerName: "Pending Amount", field: "pendingAmount", flex: 1,
            renderHeader: () => (
                <Typography style={headerStyles}>
                Pending Amount
                </Typography>
            ),
            renderCell: params => (
            <Typography style={cellStyles}>
                {params.value}
            </Typography>
        ) },
        {
            headerName: "Action", field: "action",
            renderHeader: () => (
                <Typography style={headerStyles}>
                Action
                </Typography>
            ),
            renderCell: params => (
                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <MoreVertIcon onClick={(event) => handleMenuClick(event, params.row)} />
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {/* <MenuItem onClick={() => { handleMenuClose(); navigate(`/editprofile/${selectedStudent.parentId}?${selectedStudent.itemNumber}`); }}>
                            <ListItemIcon>
                                <img src={EditIcon} alt="Edit" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Edit
                        </MenuItem> */}
                        <MenuItem onClick={() => { handleMenuClose(); }}>
                            <ListItemIcon>
                                <img src={TransferIcon} alt="Transfer" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Transfer
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); /* Add expel logic here */ }}>
                            <ListItemIcon>
                                <img src={ExpelIcon} alt="Expel" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Expel
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); /* Add suspend logic here */ }}>
                            <ListItemIcon>
                                <img src={SuspendIcon} alt="Suspend" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Suspend
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); /* Add suspend logic here */ }}>
                            <ListItemIcon>
                                <img src={CompleteIcon} alt="Suspend" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Complete
                        </MenuItem>
                        <MenuItem onClick={() => { setSelectedStudent(selectedStudent); setOpenDeleteModal(true); }}>
                            <ListItemIcon>
                                <img src={DeactivateIcon} alt="Deactivate" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Deactivate
                        </MenuItem>
                    </Menu>
                </Box>
            )
        },
    ];
    const activeColumnsColleges = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Admission No</strong>) }, renderCell: params => (
                <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer", fontSize: '12px' }} onClick={() => navigate(`/school/student/${params.row.parentId}/itemNumber?${params.value}`)}>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Student Name", field: "studentName", flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Student Name</strong>) },
            renderCell: params => (
                <Typography style={{ fontSize: '12px' }}>
                    {params.value}
                </Typography>
            )
        },
        { headerName: "Course", field: "grade", flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Course</strong>) }, renderCell: (params) => <Typography style={{ fontSize: '12px' }}>   {params.value}</Typography> },
        { headerName: "Mobile Number", field: "parentPhone", flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Mobile Number</strong>) }, renderCell: (params) => <Typography style={{ fontSize: '12px' }}>   {params.value}</Typography> },
        { headerName: "Pending Invoices", field: "pendingInvoices", flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Pending Invoices</strong>) }, renderCell: (params) => <Typography style={{ fontSize: '12px' }}>   {params.value}</Typography> },
        { headerName: "Pending Amount", field: "pendingAmount", flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Pending Amount</strong>) }, renderCell: (params) => <Typography style={{ fontSize: '12px' }}>   {params.value}</Typography> },
        {
            headerName: "Action", field: "action", renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} > Action</strong>) },
            renderCell: params => (
                // <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                //     {/* <MoreVertIcon /editprofile/:customerId /> */}
                    // <Edit style={{ cursor: 'pointer' }} onClick={() => navigate(`/editprofile/${params.row.parentId}?${params.row.itemNumber}`)} />
                // </Box>
                <Box component="div" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <MoreVertIcon onClick={(event) => handleMenuClick(event, params.row)} />
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => navigate(`/editprofile/${params.row.parentId}?${params.row.itemNumber}`)}>
                            <ListItemIcon>
                                <img src={EditIcon} alt="Edit" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); }}>
                            <ListItemIcon>
                                <img src={TransferIcon} alt="Transfer" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Transfer
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); /* Add expel logic here */ }}>
                            <ListItemIcon>
                                <img src={ExpelIcon} alt="Expel" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Expel
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); /* Add suspend logic here */ }}>
                            <ListItemIcon>
                                <img src={SuspendIcon} alt="Suspend" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Suspend
                        </MenuItem>
                        <MenuItem onClick={() => { handleMenuClose(); /* Add suspend logic here */ }}>
                            <ListItemIcon>
                                <img src={CompleteIcon} alt="Suspend" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Complete
                        </MenuItem>
                        <MenuItem onClick={() => { setSelectedStudent(selectedStudent); setOpenDeleteModal(true); }}>
                            <ListItemIcon>
                                <img src={DeactivateIcon} alt="Deactivate" style={{ width: '20px', height: '20px' }} />
                            </ListItemIcon>
                            Deactivate
                        </MenuItem>
                    </Menu>
                </Box>
            )
        },
    ];


    function formatDate(inputDate) {
        const dateObj = new Date(inputDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return dateObj.toLocaleDateString('en-US', options);
    }

    const suspendedColumns = [
        {
            headerName: "Parent Name", field: "customerName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value}
                </Typography>
            )
        },
        { headerName: "Mobile No", field: "mobileNumber", flex: 1, },
        { headerName: "Email", field: "email", flex: 1 },
        {
            headerName: "Students", field: "billableItems", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value?.length}
                </Typography>
            )
        },
        {
            headerName: "Deactivated On", field: "createdOn", flex: 1,
            renderCell: params => (
                <Typography>
                    {formatDate(params.value)}
                </Typography>
            )
        },
        {
            headerName: "Action", field: "action", flex: 1,
            renderCell: params => (
                <Box sx={{ display: "flex" }}>
                    <Box sx={{
                        width: "1.313rem",
                        height: "1.313rem",
                        borderRadius: "50%",
                        border: "1px solid #17ae7b",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginX: 0.5,
                        cursor: "pointer"
                    }}
                    >
                        <ReplayIcon sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }} />
                    </Box>
                </Box>
            )
        },
    ];

    const deactivatedColumns = [
        {
            headerName: "Student Name", field: "studentName", flex: 1,
            renderCell: params => (
                <Typography>{params.value}</Typography>
            )
        },
        {
            headerName: "Parent Name", field: "parentName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value}
                </Typography>
            )
        },
        { headerName: "Mobile No", field: "parentPhone", flex: 1, },
        { headerName: "Email", field: "parentEmail", flex: 1 },
        { headerName: "Stream", field: "stream", flex: 1},
        { headerName: "Student Admission No", field: "itemNumber", flex: 1},
        {
            headerName: "Deactivated On", field: "updatedAt", flex: 1,
            renderCell: params => (
                <Typography>
                    {formatDate(params.value)}
                </Typography>
            )
        },
        // {
        //     headerName: "Action", field: "action", flex: 1,
        //     renderCell: params => (
        //         <Box sx={{ display: "flex" }}>
        //             <Box sx={{
        //                 width: "1.313rem",
        //                 height: "1.313rem",
        //                 borderRadius: "50%",
        //                 border: "1px solid #17ae7b",
        //                 display: "flex",
        //                 flexDirection: "column",
        //                 justifyContent: "center",
        //                 marginX: 0.5,
        //                 cursor: "pointer"
        //             }}
        //             >
        //                 <ReplayIcon sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }} />
        //             </Box>
        //         </Box>
        //     )
        // },
    ];

    const deactivatedCollegeColumns = [
        {
            headerName: "Parent Name", field: "parentName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value}
                </Typography>
            )
        },
        { headerName: "Mobile No", field: "parentPhone", flex: 1, },
        { headerName: "Email", field: "parentEmail", flex: 1 },
        {
            headerName: "Student Admission", field: "itemNumber", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.value}
                </Typography>
            )
        },
        {
            headerName: "Deactivated On", field: "updatedAt", flex: 1,
            renderCell: params => (
                <Typography>
                    {formatDate(params.value)}
                </Typography>
            )
        },
        { headerName: "Course Name  ", field: "grade", flex: 1, },
    ];

    const completedColumn = [
        {
            headerName: "Admission No", field: "itemNumber", flex: 1, renderCell: params => (
                <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/student/${params.row.parentId}/${params.row?.itemNumber}`)}>
                    {schoolTypeName?.includes("University") ? params?.row?.itemNumber : params?.row?.itemNumber}
                </Typography>
            )
        },
        { headerName: "studentName", field: 'studentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) }, renderCell: (params) => <span>{params?.row?.firstName} {params?.row?.lastName}</span> },
        { headerName: "grade", field: 'grade', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Grade</strong>) } },
        { headerName: "stream", field: 'stream', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Stream</strong>) } },
        { headerName: "boardingStatus", field: 'boardingStatus', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Boarding Status</strong>) } },
        { headerName: "parentName", field: 'parentName', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Parent Name</strong>) } },
        { headerName: "parentPhone", field: 'parentPhone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Mobile Number</strong>) } },
        { headerName: "pendingInvoices", field: 'pendingInvoices', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Pending Invoices</strong>) } },
        { headerName: "pendingAmount", field: 'pendingAmount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Pending  Amount</strong>) } },
        { headerName: "status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) } },
    ]

    const csvColumns = [
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Stream", key: "Stream" },
        { label: "Boarding Status", key: "Boarding Status" },
        { label: "Parent Name", key: "Parent Name" },
        { label: "Mobile Number", key: "Mobile Number" },
        { label: "Pending Invoices", key: "Pending Invoices" },
        { label: "Pending Amount", key: "Pending Amount" },
    ];
    const csvColumnsColleges = [
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Course", key: "Grade" },
        { label: "Mobile Number", key: "Mobile Number" },
        { label: "Pending Invoices", key: "Pending Invoices" },
        { label: "Pending Amount", key: "Pending Amount" },
    ];

    const fileHeaders = [[
        "Admission No",
        "Student Name",
        "Grade",
        "Stream",
        "Boarding Status",
        "Parent Name",
        "Mobile Number",
        "Pending Invoices",
        "Pending Amount"
    ]]
    const fileHeadersColleges = [[
        "Admission No",
        "Student Name",
        "Course",
        "Mobile Number",
        "Pending Invoices",
        "Pending Amount"
    ]]

    const handleDeleteRecipient = () => {
        if (selectedStudent) {
            HttpComponent({
                method: 'GET',
                url: `/api/deactivateCustomer?customerId=${selectedStudent.studentId}`,
                token: X_Authorization
            }).then((response) => {
                console.log('response.data', response.status);
                if (response.status === 202) {
                    fetchParents();
                    setOpenDeleteModal(false);
                    setSuccessShow({ state: true, message: response.response.message });
                    setTimeout(() => {
                        setSuccessShow({ state: false, message: "" });
                    }, 2000);
                } else {
                    console.error("Error deactivating student:", response.message);
                    setErrorShow({ state: true, message: response.message });
                    setTimeout(() => {
                        setErrorShow({ state: false, message: "" });
                    }, 2000);
                }
            }).catch((error) => {
                console.error("Error:", error);
                setErrorShow({ state: true, message: "An error occurred while deactivating the student." });
                setTimeout(() => {
                    setErrorShow({ state: false, message: "" });
                }, 2000);
            });
        }
    };

    const fileDataPDF = dataToShow.map((student) => [
        student?.itemNumber,
        `${student?.firstName} ${student?.lastName}`,
        student?.grade,
        student?.stream,
        student?.boardingStatus,
        student?.parentName,
        student?.parentPhone,
        student?.pendingInvoices,
        student?.pendingAmount,
    ]);
    const fileDataPDFColleges = dataToShow.map((student) => [
        student?.itemNumber,
        `${student?.firstName} ${student?.lastName}`,
        student?.grade,
        student?.parentPhone,
        student?.pendingInvoices,
        student?.pendingAmount,
    ]);


    const fileData = dataToShow.map((student) => {
        return {
            "Admission No": student?.itemNumber,
            "Student Name": `${student?.firstName} ${student?.lastName}`,
            "Grade": student?.grade,
            "Stream": student?.stream,
            "Boarding Status": student?.boardingStatus,
            "Parent Name": student?.parentName,
            "Mobile Number": student?.parentPhone,
            "Pending Invoices": student?.pendingInvoices,
            "Pending Amount": student?.pendingAmount,
        };
    });
    const fileDataColleges = dataToShow.map((student) => {
        return {
            "Admission No": student?.itemNumber,
            "Student Name": `${student?.firstName} ${student?.lastName}`,
            "Course": student?.grade,
            "Mobile Number": student?.parentPhone,
            "Pending Invoices": student?.pendingInvoices,
            "Pending Amount": student?.pendingAmount,
        };
    });
    const businessName = localStorage.getItem('businessName')


    return (
        <Box component="div">
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <Box component="div" sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box component="div">
                    <Box component="div" sx={{}}>
                        <Typography sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700 }}>
                            Students
                        </Typography>
                    </Box>
                    <Box component="div" sx={{}}>
                        <Breadcrumbs
                            separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                            aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                </Box>
                {schoolTypeName?.includes("University") ?
                    <Box component="div" sx={{ gap: 2 }}>

                        <Button sx={{
                            "width": "125px",
                            "height": "45px",
                            "borderRadius": "4px",
                            "backgroundColor": "#f5f6f7",
                            marginX: 1,
                            color: "#032541",
                            "&:hover": {
                                color: "#032541",
                                "backgroundColor": "#f5f6f7",
                            },
                        }} onClick={() => navigate("/school/parent/add/upload")}>
                            Upload File
                        </Button>

                        <Button sx={createButton} onClick={() => navigate("/school/parent/add")}>
                            Add
                        </Button>
                    </Box>
                    : null}

            </Box>



            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                            style: { display: 'none' },
                        }}
                    >
                        <Tab label="Active" value="ACTIVE" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />
                        <Tab label="Transferred" value="Transferred" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                        <Tab label="Suspended" value="Suspended" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                        <Tab label="Deactivated" value="Deactivated" centered sx={{
                            color: "#6e7074",
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                        <Tab label="Expelled" value="Expelled" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                        <Tab label="Completed" value="COMPLETED" centered sx={{
                            color: '#6e7074',
                            '&.Mui-selected': {
                                color: '#dc3545',
                            }
                        }} />

                    </TabList>
                </Box>

                <TabPanel value="ACTIVE">
                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                        <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                                <CustomSearchInput name={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search:" />
                                {schoolTypeName === 'Kindergarten/Junior/High School' ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                                            <InputLabel id="year-select-label">School Type</InputLabel>
                                            <Select
                                                labelId="year-select-label"
                                                id="year-select"
                                                value={selectedSchoolType}
                                                label="School Type"
                                                onChange={(e) => setSelectedSchoolType(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    Select School
                                                </MenuItem>
                                                {allSchoolCategory.map((school) => (
                                                    <MenuItem key={school} value={school}>
                                                        {school}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> : null
                                }
                                <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={schoolTypeName?.includes("University") ? 'Select Year' : "Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                                {schoolTypeName?.includes("University") ? null :
                                    <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                                }

                            </Box>
                            <ExportMenu
                                csvColumns={schoolTypeName?.includes("University") ? csvColumnsColleges : csvColumns}
                                fileData={schoolTypeName?.includes("University") ? fileDataColleges : fileData}
                                fileHeaders={schoolTypeName?.includes("University") ? fileHeadersColleges : fileHeaders}
                                fileDataPDF={schoolTypeName?.includes("University") ? fileDataPDFColleges : fileDataPDF}
                                title={"BusinessName :" + businessName + "Report Type: " + "   Active Students Report  " + "Print Date : " + new Date().toLocaleDateString()}
                                fileName={"ActiveStudents"}
                            />
                        </Box>
                        <Grid item>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.count}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState.page - 1}
                                pageSize={pageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: pageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={schoolTypeName?.includes("University") ? activeColumnsColleges : activeColumns}
                                rows={rows}
                                getRowId={row => row.id} />
                        </Grid>
                        {/* <CustomTable loading={gridLoading} data={rows} columns={schoolTypeName?.includes("University") ? activeColumnsColleges : activeColumns} rowsPerPage={5} /> */}
                    </Box>

                </TabPanel>

                <TabPanel value="Transferred">


                </TabPanel>

                <TabPanel value="Suspended">


                </TabPanel>

                <TabPanel value="Deactivated">
                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                        <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                                <CustomSearchInput name={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search:" />
                                {schoolTypeName === 'Kindergarten/Junior/High School' ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                                            <InputLabel id="year-select-label">School Type</InputLabel>
                                            <Select
                                                labelId="year-select-label"
                                                id="year-select"
                                                value={selectedSchoolType}
                                                label="School Type"
                                                onChange={(e) => setSelectedSchoolType(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    Select School
                                                </MenuItem>
                                                {allSchoolCategory.map((school) => (
                                                    <MenuItem key={school} value={school}>
                                                        {school}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> : null
                                }
                                <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={schoolTypeName?.includes("University") ? 'Select Year' : "Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                                {schoolTypeName?.includes("University") ? null :
                                    <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                                }

                            </Box>
                            <ExportMenu
                                csvColumns={schoolTypeName?.includes("University") ? csvColumnsColleges : csvColumns}
                                fileData={schoolTypeName?.includes("University") ? fileDataColleges : fileData}
                                fileHeaders={schoolTypeName?.includes("University") ? fileHeadersColleges : fileHeaders}
                                fileDataPDF={schoolTypeName?.includes("University") ? fileDataPDFColleges : fileDataPDF}
                                title={"BusinessName :" + businessName + "Report Type: " + "   Active Students Report  " + "Print Date : " + new Date().toLocaleDateString()}
                                fileName={"ActiveStudents"}
                            />
                        </Box>
                        <Grid item>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.count}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState.page - 1}
                                pageSize={pageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: pageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={schoolTypeName?.includes("University") ? deactivatedCollegeColumns : deactivatedColumns}
                                rows={rows}
                                getRowId={row => row.id} />
                        </Grid>
                        {/* <CustomTable loading={gridLoading} data={rows} columns={schoolTypeName?.includes("University") ? activeColumnsColleges : activeColumns} rowsPerPage={5} /> */}
                    </Box>

                </TabPanel>

                <TabPanel value="Expelled">


                </TabPanel>
                <TabPanel value="COMPLETED">
                    <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                        <Box component="div" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                                <CustomSearchInput name={"search"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search:" />
                                {schoolTypeName === 'Kindergarten/Junior/High School' ?
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                                            <InputLabel id="year-select-label">School Type</InputLabel>
                                            <Select
                                                labelId="year-select-label"
                                                id="year-select"
                                                value={selectedSchoolType}
                                                label="School Type"
                                                onChange={(e) => setSelectedSchoolType(e.target.value)}
                                            >
                                                <MenuItem value="">
                                                    Select School
                                                </MenuItem>
                                                {allSchoolCategory.map((school) => (
                                                    <MenuItem key={school} value={school}>
                                                        {school}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div> : null
                                }
                                <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={schoolTypeName?.includes("University") ? 'Select Year' : "Select Grade"} options={gradeOptions} sm={{ maxWidth: "200px" }} />
                                {schoolTypeName?.includes("University") ? null :
                                    <CustomSelectField style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }} value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"Select Streams"} options={streamsOptions} sm={{ maxWidth: "200px" }} />
                                }

                            </Box>
                            <ExportMenu
                                csvColumns={schoolTypeName?.includes("University") ? csvColumnsColleges : csvColumns}
                                fileData={schoolTypeName?.includes("University") ? fileDataColleges : fileData}
                                fileHeaders={schoolTypeName?.includes("University") ? fileHeadersColleges : fileHeaders}
                                fileDataPDF={schoolTypeName?.includes("University") ? fileDataPDFColleges : fileDataPDF}
                                title={"BusinessName :" + businessName + "Report Type: " + "   Active Students Report  " + "Print Date : " + new Date().toLocaleDateString()}
                                fileName={"ActiveStudents"}
                            />
                        </Box>

                        <Grid item>
                            <DataGrid
                                components={{ NoRowsOverlay: NoRowsOverlay }}
                                sx={{ height: '550px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                                rowCount={pageState?.count}
                                loading={pageState?.isLoading}
                                pagination
                                page={pageState.page - 1}
                                pageSize={pageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: pageSize })); }}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                columns={completedColumn}
                                rows={pageState?.data}
                                getRowId={row => row?.studentId} />
                        </Grid>
                    </Box>
                </TabPanel>
            </TabContext>
            <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                    <Box>
                        <img src={WarningIcon} alt="warning icon" width={70} height={70} />
                    </Box>
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: '600', color: '#032541', fontSize: '16px', fontFamily: 'Poppins' }}>
                            Deactivate Student?
                        </Typography>
                        <br />
                        <DialogContentText>
                            Are you sure you want to Deactivate <br /> <strong style={{ color: '#032541' }}>{selectedStudent?.studentName}?</strong>
                        </DialogContentText>
                    </Box>
                </DialogTitle>
                <DialogActions sx={{ justifyContent: 'center', marginBottom: '30px' }}>
                    <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: '1px solid #002543', color: "#002543", fontSize: '14px', fontWeight: '500', fontFamily: 'Poppins' }} onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                    <Button style={{ height: "45px", width: "125px", fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }} onClick={handleDeleteRecipient} color="error" variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default SchoolListStudents;
