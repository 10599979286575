import { Box, Breadcrumbs, Button, Grid, Menu, MenuItem, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabList from "@mui/lab/TabList";
import HttpComponent from "../School/MakeRequest";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import TabContext from "@mui/lab/TabContext";
import { ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "../School/CustomSearchInput";

function ListOpenEvouchers() {
    const localCurrency = localStorage.getItem('localCurrency')
    const navigate = useNavigate();
    const [evoucherData, setEvoucherData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [tabValue, setTabValue] = useState("Business Vouchers");
    const [bulkEvoucherData, setBulkEvoucherData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(0);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
            Dashboard
        </Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
            Open E-Vouchers
        </Typography>
    ];
    const AntTabs = styled(TabList)({
        borderBottom: "0px solid #e8e8e8",
        "& .MuiTabs-indicator": {
            backgroundColor: "#ffffff00",
        },
    });
    const handleSearchBox = (event) => {
        setSearchValue(event.target.value);
    };
    const getEvoucherDetails = async (type) => {
        try {
            const url = `/api/v1/evoucher/getEvouchers?pageLimit=${rowsPerPage}&pageNumber=${page + 1}&status=ACTIVE&paymentStatus=PAID&type=${type === "Bulk Vouchers" ? "batch" : "normal"}`;
            const request = await HttpComponent({
                method: "GET",
                url: url,
                token: localStorage.getItem("X-Authorization"),
            });
            const filteredData = request.response.data.filter(voucher => voucher.servicesCount === 0);

            if (type === "Business Vouchers") {
                setEvoucherData(filteredData);
            } else {
                setBulkEvoucherData(filteredData);
            }
            setTotalCount(request.response.count);
        } catch (error) {
            setErrorShow(error.response ? error.response.data.message : error.message);
            // setErrorOpen(true);
        }
    };

    useEffect(() => {
        getEvoucherDetails(tabValue);
    }, [tabValue, page]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setPage(0);
        getEvoucherDetails(newValue);
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
        minWidth: 0,
        [theme.breakpoints.up("sm")]: { minWidth: 0 },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        fontSize: "13px",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541", opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545", fontWeight: 600,
        },
    }));
    const handleCreateEvoucher = (type) => {
        if (type === "Personal E-Voucher") {
            navigate("/create/personal/evoucher");
        } else if (type === "Business E-Voucher") {
            console.log(`Creating ${type}`);
        }
        handleMenuClose();
    };
    const handleVoucherClick = (voucher) => {
        navigate(`/open-evoucher/${voucher._id}`, { state: { batchNumber: voucher.batchNumber, customerName: voucher.name, customerPhone: voucher.phone, voucherType: tabValue } });
    };
    return (
        <div>
            <Grid >
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <Grid >
                    <Grid item mt={1}>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Other Payments</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <TabContext value={tabValue}>
                        <Grid container width="100%" sx={{ display: 'flex', justifyContent: 'space-between', padding: "5px 20px" }}>
                            <Grid item>
                                <Grid item>
                                    <AntTabs onChange={handleTabChange} aria-label="Shop tabs" TabIndicatorProps={{ hidden: true }} textColor="primary">
                                        <AntTab label="Business Vouchers" value="Business Vouchers" />
                                        <AntTab label="Bulk Vouchers" value="Bulk Vouchers" />
                                    </AntTabs>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={handleMenuClick} style={{ fontFamily: "Poppins", color: "#f5f5f5", marginRight: '10px', borderRadius: "4px", backgroundColor: "#032541", height: "45px", width: "200px", display: 'flex', alignItems: 'center' }}>
                                        Create E-Voucher
                                        <ExpandMore />
                                    </Button>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                        <MenuItem onClick={() => handleCreateEvoucher("Personal E-Voucher")}>Personal E-Voucher</MenuItem>
                                        <MenuItem onClick={() => handleCreateEvoucher("Business E-Voucher")}>Business E-Voucher</MenuItem>
                                    </Menu>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Box sx={{ "& .super-app-theme--header": { color: "#032541", fontWeight: "600", fontSize: "10px" } }}>
                                <CustomSearchInput name="search" value={searchValue} placeholder="Search:" onChange={handleSearchBox} />
                            </Box>
                        </Grid>
                        <TabContext value={tabValue}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Batch ID</TableCell>
                                            <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Created On</TableCell>
                                            <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Customer Name</TableCell>
                                            <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Phone Number</TableCell>
                                            <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Voucher Count</TableCell>
                                            <TableCell style={{ color: '#032541', fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>Total Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(tabValue === "Business Vouchers" ? evoucherData : bulkEvoucherData).length > 0 ? (
                                            (tabValue === "Business Vouchers" ? evoucherData : bulkEvoucherData).map((voucher) => (
                                                <TableRow sx={{ cursor: 'pointer' }} key={voucher._id} onClick={() => handleVoucherClick(voucher)}>
                                                    <TableCell>{tabValue === "Business Vouchers" ? voucher.batchNumber : voucher.batchNumber}</TableCell>
                                                    <TableCell>{new Date(voucher.createdAt).toLocaleDateString()}</TableCell>
                                                    <TableCell>{voucher.name}</TableCell>
                                                    <TableCell>{voucher.phone}</TableCell>
                                                    <TableCell>{voucher.quantity}</TableCell>
                                                    <TableCell>{localCurrency} {voucher.amount.toFixed(2)}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={tabValue === "Bulk Vouchers" ? 7 : 6} style={{ textAlign: 'center' }}>
                                                    No e-vouchers available.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabContext>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                const newRowsPerPage = parseInt(event.target.value, 10);
                                setRowsPerPage(newRowsPerPage);
                                setPage(0);
                                getEvoucherDetails(tabValue);
                            }}
                        />
                    </TabContext>
                </Grid>
            </Grid >
        </div >
    )
}
export default ListOpenEvouchers