import React, { useEffect, useState } from 'react';
import HttpComponent from '../School/MakeRequest';
import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ErrorAlert } from '../snackBar Alerts/errorAlert';
import { SuccessAlert } from '../snackBar Alerts/successAlert';
import { AddCircleOutline, ArrowBackIos, CalendarToday, Delete, Edit } from '@mui/icons-material';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomTextField from '../School/CustomTextField';
import CustomInputLabel from '../School/CustomInputLabel';
import PhoneInput from 'react-phone-input-2';
import CreateEvoucherReceipient from './createEvoucherReceipient';
import EditEvoucherReceipient from './editEvoucherReceipient';
import WarningIcon from '../../components/School/Images/warning-remove-icn.svg'
import PayPersonalVoucher from './payEvoucher';

function CreateOpenEvoucher() {
    const navigate = useNavigate();
    const [openReceipientModal, setOpenReceipientModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [formData, setFormData] = useState({ phone: "", email: "", customerId: "", });
    const [editingRecipient, setEditingRecipient] = useState(null);
    const localCurrency = localStorage.getItem("localCurrency");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedRecipient, setSelectedRecipient] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [voucherStartDate, setVoucherStartDate] = useState("");
    const [voucherExpiryDate, setVoucherExpiryDate] = useState("");
    const [openPayVoucherModal, setOpenPayVoucherModal] = useState(false);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const handleCancel = () => navigate(-1);
    const [totalAmount, setTotalAmount] = useState(0);
    const breadcrumbs = [
        <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>Dashboard</Typography>,
        <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>Open E-Vouchers</Typography>,
        <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>Create Personal E-Voucher</Typography>
    ];

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const isFormValid = () => {
        return formData.phone && formData.email && recipients.length > 0;
    };

    const handleCancelReceipient = () => {
        setOpenReceipientModal(false);
    }

    const handleCancelPayVoucher = () => {
        setOpenPayVoucherModal(false);
    }

    const handleCancelEditReceipient = () => {
        setOpenEditModal(false);
    }

    const addRecipient = (recipient) => {
        setRecipients([...recipients, recipient]);
        setOpenReceipientModal(false);
    };

    const handleEditRecipient = (recipient) => {
        setEditingRecipient(recipient);
        setOpenEditModal(true);
    };

    const calculateTotalAmount = () => {
        const total = recipients.reduce((acc, recipient) => acc + Number(recipient.eVoucherAmount), 0);
        console.log('Total Amount:', total);
        setTotalAmount(total);
    };
    useEffect(() => {
        calculateTotalAmount();
    }, [recipients]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            setErrorShow({ state: true, message: "Please fill in all required fields and add at least one recipient." });
            return;
        }
        const totalAmount = recipients.reduce((acc, recipient) => acc + Number(recipient.eVoucherAmount), 0);

        const payload = {
            expiryDate: new Date(voucherExpiryDate).toISOString(),
            phoneNumber: formData.phone,
            recipients: recipients.map(recipient => ({
                amount: Number(recipient.eVoucherAmount),
                email: recipient.email,
                name: recipient.receipientName,
                phone: recipient.phone
            })),
            customerId: formData.customerId,
            startDate: new Date(voucherStartDate).toISOString(),
        };
        console.log('payload', payload);

        try {
            const response = await HttpComponent({
                method: 'POST',
                url: '/api/v1/evoucher/createEvoucher',
                token: localStorage.getItem("X-Authorization"),
                body: payload
            });
            console.log('Response:', response);

            if (response.status === 200) {
                setSuccessShow({ state: true, message: "E-Voucher created successfully!" });
                const { batchNumber } = response.response.data;
                setTimeout(() => {
                    setOpenPayVoucherModal({ open: true, batchNumber, amount: totalAmount }); // Pass the total amount
                }, 2000);
            } else {
                setErrorShow({ state: true, message: response.response.message || "Failed to create E-Voucher." });
            }
        } catch (err) {
            setErrorShow({ state: true, message: err.message || "An error occurred while creating the E-Voucher." });
        }
    };
    const updateRecipient = (updatedRecipient) => {
        setRecipients((prevRecipients) => {

            const updatedRecipients = prevRecipients.map((recipient) => {
                return recipient.id === updatedRecipient.id ? updatedRecipient : recipient;
            });

            return updatedRecipients;
        });
        setOpenEditModal(false);
    };

    const handleDeleteRecipient = () => {
        setRecipients((prevRecipients) => {
            const updatedRecipients = prevRecipients.filter(recipient => recipient.id !== selectedRecipient.id);

            setSuccessShow(`Recipient ${selectedRecipient.receipientName} was deleted successfully.`);
            // setSuccessOpen(true);
            setTimeout(() => {
                setSuccessShow(false);
            }, 3000);

            return updatedRecipients;
        });
        setOpenDeleteModal(false);
        setSelectedRecipient(null);
    };
    const fetchCustomers = async () => {
        try {
            const response = await HttpComponent({
                method: 'GET',
                url: '/api/customers_list?status=ACTIVE',
                token: localStorage.getItem("X-Authorization"),
            });
            if (response.response.Status === "SUCCESS") {
                setCustomers(response.response.data);
            } else {
                setErrorShow({ state: true, message: response.data.message });
            }
        } catch (err) {
            setErrorShow({ state: true, message: err.message });
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const getEvoucherValidity = async () => {
        try {
            const response = await HttpComponent({
                method: 'POST',
                url: '/api/get_business_info',
                token: localStorage.getItem("X-Authorization"),
            });
            if (response.response.Status === "SUCCESS") {
                const data = response.response.data;

                // Check if voucherStartDate and voucherExpiryDate exist before splitting
                if (data.voucherStartDate) {
                    setVoucherStartDate(data.voucherStartDate.split('T')[0]);
                } else {
                    setErrorShow({ state: true, message: "Voucher start date is not available." });
                }

                if (data.voucherExpiryDate) {
                    setVoucherExpiryDate(data.voucherExpiryDate.split('T')[0]);
                } else {
                    setErrorShow({ state: true, message: "Voucher expiry date is not available." });
                }
            } else {
                setErrorShow({ state: true, message: response.data.message });
            }
        } catch (err) {
            setErrorShow({ state: true, message: err.message });
        }
    };

    // const getEvoucherValidity = async () => {
    //     try {
    //         const response = await HttpComponent({
    //             method: 'POST',
    //             url: '/api/get_business_info',
    //             token: localStorage.getItem("X-Authorization"),
    //         });
    //         if (response.response.Status === "SUCCESS") {
    //             const data = response.response.data;
    //             setVoucherStartDate(data.voucherStartDate.split('T')[0]);
    //             setVoucherExpiryDate(data.voucherExpiryDate.split('T')[0]);
    //         } else {
    //             setErrorShow({ state: true, message: response.data.message });
    //         }
    //     } catch (err) {
    //         setErrorShow({ state: true, message: err.message });
    //     }
    // };

    useEffect(() => {
        getEvoucherValidity();
    }, []);
    return (
        <div>
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <div style={{ flexGrow: 1, overflow: "hidden" }}>
                    {openReceipientModal ? (
                        <CreateEvoucherReceipient setOpenReceipientModal={setOpenReceipientModal} handleCancelReceipient={handleCancelReceipient} addRecipient={addRecipient} />
                    ) : openEditModal ? (
                        <EditEvoucherReceipient recipient={editingRecipient} setOpenEditModal={setOpenEditModal} updateRecipient={updateRecipient} handleCancelEditReceipient={handleCancelEditReceipient} />
                    ) : openPayVoucherModal ? (
                        <PayPersonalVoucher setOpenPayVoucherModal={setOpenPayVoucherModal} handleCancelPayVoucher={handleCancelPayVoucher} batchNumber={openPayVoucherModal.batchNumber} amount={totalAmount} />
                    ) : (
                        <>
                            <Box sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden", p: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    <IconButton style={{ position: 'relative', top: '40px', right: '10px' }} onClick={handleCancel}>
                                        <ArrowBackIos />
                                    </IconButton>
                                    <Typography variant="h3" sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 700, marginLeft: '30px' }}>
                                        Create Personal E-Voucher
                                    </Typography>
                                    <Breadcrumbs sx={{ marginTop: '20px' }} separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                                        {breadcrumbs}
                                    </Breadcrumbs>
                                </Box>
                            </Box>
                            <Grid item>
                                <Typography style={{ fontSize: "14px", color: "#707070", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                                    Create e-vouchers for you and your friends by adding recipients and <br /> setting amounts for each voucher.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontSize: "14px", color: "#707070", marginTop: "10px", fontFamily: 'Poppins', marginLeft: '20px' }}>
                                    Create e-vouchers for you and your friends by adding recipients and <br /> setting amounts for each voucher.
                                </Typography>
                            </Grid>
                            <Grid container spacing={2} style={{ width: '50%', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#000", fontFamily: 'Poppins', lineHeight: '2.06', ml: 3 }}>
                                        1. Add Recipients
                                    </Typography>
                                    <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => { setOpenReceipientModal(true); }}>
                                        <AddCircleOutline sx={{ mr: 1, color: "#000", fontSize: 20 }} />
                                        <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#000", fontFamily: 'Poppins', lineHeight: '2.06' }}>
                                            Add
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box component="div" sx={{ display: 'flex', flexDirection: "column", marginX: 1, marginTop: '10px' }}>
                                {recipients.length > 0 ? (
                                    recipients.map((recipient, index) => (
                                        <Box key={index} sx={{ border: "1px solid #ccc", borderRadius: "12px", padding: 2, marginBottom: 2, display: "flex", justifyContent: "space-between", width: '550px' }}>
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {recipient.receipientName}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {recipient.phone}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                                <Typography variant="body1" fontWeight="bold" sx={{ color: '#17ae7b' }}>
                                                    {localCurrency} {Number(recipient.eVoucherAmount).toFixed(2)}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {recipient.email}
                                                </Typography>
                                            </Box>
                                            <IconButton edge="end" aria-label="edit" onClick={() => handleEditRecipient(recipient)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={() => { setSelectedRecipient(recipient); setOpenDeleteModal(true); }}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    ))
                                ) : (
                                    <Box component="div" sx={{ display: 'flex', flexDirection: "column", marginX: 1, marginTop: '10px' }}>
                                        <Box sx={{ marginLeft: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <CustomTextField value={formData.receipientDetails} onChange={handleInputChange} name={'receipientDetails'} placeholder={"Add recipients who will receive the e-voucher"} multiline rows={2} sx={{ flexGrow: 1, marginRight: '10px' }} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Grid item>
                                <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#000", fontFamily: 'Poppins', lineHeight: '2.06', ml: 3 }}>
                                    2. Payment Details
                                </Typography>
                            </Grid>
                            <Box component="div" sx={{ display: 'flex', marginLeft: '20px' }}>
                                <Box>
                                    <CustomInputLabel label={'Customer Name'} required={true} />
                                    <FormControl style={{ minWidth: '410px' }}>
                                        <Select value={formData.customerId} onChange={handleInputChange} name={'customerId'} displayEmpty >
                                            <MenuItem value="">
                                                <em>Select Customer</em>
                                            </MenuItem>
                                            {customers.map((customer) => (
                                                <MenuItem key={customer._id} value={customer._id}>
                                                    {customer.customerName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box component="div" sx={{ display: 'flex', marginLeft: '20px', marginTop: '10px' }}>
                                <Box>
                                    <CustomInputLabel label={'Mobile Number'} required={true} />
                                    <Box component="div" sx={{ marginTop: 1, marginBottom: 2 }}>
                                        <PhoneInput country={"ke"} value={formData.phone} onChange={(value) => setFormData({ ...formData, phone: `+${value}` })} name={'phone'} placeholder={'Phone Number'}
                                            inputStyle={{ height: "55px", width: "408px" }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <CustomInputLabel required={true} label={'Email Address'} />
                                    <CustomTextField value={formData.email} onChange={handleInputChange} name={'email'} placeholder={"Email Address"} />
                                </Box>
                            </Box>
                            <Grid item>
                                <Typography sx={{ fontWeight: 600, fontSize: "16px", color: "#000", fontFamily: 'Poppins', lineHeight: '2.06', ml: 3 }}>
                                    3. Voucher Validity
                                </Typography>
                            </Grid>
                            <Box component="div" sx={{ display: 'flex', marginLeft: '20px', marginTop: '10px' }}>
                                <Box>
                                    <CustomInputLabel label={'Voucher Start Date'} required={true} />
                                    <TextField type="date" value={voucherStartDate}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarToday sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                                </InputAdornment>
                                            ),
                                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                                        }}
                                        sx={{
                                            width: '400px', marginTop: '7px',
                                            '& .MuiInputBase-input': { paddingRight: '40px', }
                                        }}
                                    />
                                </Box>
                                <Box sx={{ marginLeft: '10px' }}>
                                    <CustomInputLabel label={'Voucher Expiry Date'} required={true} />
                                    <TextField type="date" value={voucherExpiryDate}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarToday sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                                </InputAdornment>
                                            ),
                                            style: { color: 'rgba(0, 0, 0, 0.54)' },
                                        }}
                                        sx={{
                                            width: '450px', marginTop: '7px',
                                            '& .MuiInputBase-input': { paddingRight: '40px', }
                                        }}
                                    />
                                </Box>
                            </Box>
                            <form onSubmit={handleSubmit}>
                                <Box style={{ display: "flex", marginTop: "15px", justifyContent: "center" }}>
                                    <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: '1px solid #1a2e4c', color: "#032541" }}>
                                        Send Invoice
                                    </Button>
                                    <Button type="submit"
                                        variant="contained"
                                        style={{ backgroundColor: isFormValid() ? '#032541' : 'rgba(3, 37, 65, 0.4)', color: '#fff', width: '125px', height: '45px' }}
                                        disabled={!isFormValid()}
                                    >
                                        Pay Now
                                    </Button>
                                </Box>
                            </form>
                        </>
                    )}
                </div>
                <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                    <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                        <Box>
                            <img src={WarningIcon} alt="warning icon" width={70} height={70} />
                        </Box>
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: '600', color: '#032541', fontSize: '16px', fontFamily: 'Poppins' }}>
                                Remove Recipient?
                            </Typography>
                            <br />
                            <DialogContentText>
                                Are you sure you want to remove <br /> <strong style={{ color: '#032541' }}>{selectedRecipient?.receipientName}</strong> as a receipient?<br /> This action cannot be undone.
                            </DialogContentText>
                        </Box>
                    </DialogTitle>
                    <DialogActions sx={{ justifyContent: 'center', marginBottom: '30px' }}>
                        <Button style={{ height: "45px", width: "125px", marginRight: '20px', borderRadius: "4px", border: '1px solid #002543', color: "#002543", fontSize: '14px', fontWeight: '500', fontFamily: 'Poppins' }} onClick={() => setOpenDeleteModal(false)}>Cancel</Button>
                        <Button style={{ height: "45px", width: "125px", fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }} onClick={handleDeleteRecipient} color="error" variant="contained">
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}
export default CreateOpenEvoucher;