import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useState } from "react";
import BankDetailsFt from "./bankInfo";
import AddInputersApprovals from "./approvalsandinputers";


const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Invoice</Typography>,
    <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.inactive">Preview Invoice</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Fund Transfer SetUp</Typography>
];

export default function FundTransferCustomerSetUp() {
    const navigate = useNavigate()
    return (
        <Grid container width={'100%'} flex={1} flexDirection={'column'} gap={2}>
            <Grid item width={'50%'} sx={{ display: 'flex', flexDirection: 'column' }} gap={1}>
                <Box component={'div'} display={'flex'} alignItems={'center'} gap={1}>
                    <ArrowBackIosIcon sx={{color:"#707070"}} onClick={() => navigate(-1)} />
                    <Typography variant="h6" sx={{ color: "#032541", fontWeight: 700 }}>Fund Transfer Payment Setup</Typography>
                </Box>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
                <Typography variant="body2" sx={{color:"#707070"}}>Setup you KCB Bank Debit accounts and approvers to easily pay for invoices from your bank account.</Typography>
            </Grid>
            <Grid item width={'100%'}>
                <BankDetailsFt/>
            </Grid>
            <Grid item width={'100%'}>
                <AddInputersApprovals/>
            </Grid>
        </Grid>
    )
}