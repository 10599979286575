import { Box, Grid, Typography } from "@mui/material";
import NotDoneImg from '../../../images/notdone-ft.svg';
import DoneImg from '../../../images/done-ft.svg';
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";

export default function AddInputersApprovals(){
     const [errorShow, setErrorShow] = useState({ state: false, message: '' });
     const [successShow, setSuccessShow] = useState({ state: false, message: '' });
     const [isApprovalsSet, setIsApprovalsSet] = useState(false) 

     function getApprovalsSet() {
        try {
            HttpComponent({
                url: `/api/business/getapproversinbusiness`,
                method: 'GET',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data?.status === 200) {
                    setIsApprovalsSet(data?.response?.isApprovalSet);
                }
            });
        } catch (error) {}
    }
    useEffect(() => {
        getApprovalsSet();
    }, []);
    return(
        <Grid container direction={'column'} display={'flex'} width={'100%'}>
             <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
             <SuccessAlert vertical="top" horizontal="right"  onClose={() => setSuccessShow({ ...successShow, state: false })}open={successShow.state} message={successShow.message}/>
             <Grid item width={'50%'} sx={{ border: isApprovalsSet ? '1px solid #17ae7b' : '1px solid #bec5d1', paddingX: 2 }}>
                <Box component={'div'} display={'flex'} flexDirection={'column'}>
                    <Box component={'div'} width={'100%'} display={'flex'} py={2} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            Step 2: Set Up Approvals
                        </Typography>
                        <Box component={'img'} src={isApprovalsSet ? DoneImg : NotDoneImg} />
                    </Box>
                    <Typography variant="body2">Assign approvers who will allow payments made from your business to suppliers, vendors or petty cash.</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}