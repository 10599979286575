import React, { useState, useEffect, useRef } from "react";
import { Grid, Breadcrumbs, Tab, styled, Button, Typography, Box } from "@mui/material";
import { customerstyles } from "./customerComponents/styles";
import { NavigateNext } from "@mui/icons-material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Allcustomers from "./customerComponents/activecustomers";
import SuspendedCustomer from "./customerComponents/suspendedCustomer";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomerAproval from "./customerComponents/customerAproval";
import SearchFilter from "./customerComponents/searchFilter";
import { Exports } from "./customerComponents/exports";
import { CustomDate } from "./customerComponents/customDate";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from "@mui/lab/LoadingButton";
import icon from "../../images/uploadFileIcon.svg";
import csvTemplate from "./customer-Template.csv";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SendIcon from "@mui/icons-material/Send";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";


const baseUrl = process.env.REACT_APP_BASE_URL;

//breadcrumbs
const breadcrumbs = [
  <Typography style={{ color: "#707070", fontSize: "14px", fontWeight: 'normal' }}>Dashboard</Typography>,
  <Typography style={{ color: "#dc3545", fontSize: "14px", fontWeight: 'normal' }}>Customers</Typography>
]

const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(0),
  fontSize: "16px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 },
  "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const Customerlist = () => {
  const navigate = useNavigate();
  const [customersTab, setCustomersTab] = useState("Customers");
  const [Loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })


  // exported data;
  const [exportData, setExportData] = useState({ headers: [], data: [] });

  // csv data exports;

  const [csvExport, setCsvExport] = useState({ headers: [], data: [] });
  // set tabChange;
  const customersTabChange = (event, newTabValue) => {
    setCustomersTab(newTabValue);
  };

  // FILTER BY DATE;
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");

  const areaStyles = {
    borderRadius: "6px",
    border: "solid 1px #e1e1e1",
    backgroundColor: "rgba(3, 37, 65, 0.02)",
    minHeight: "20rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }

  const buttonStyling = {
    "width": "10.625rem",
    "height": "2.813rem",
    "borderRadius": "5px",
    "backgroundColor": "#032541",
    color: "white",
    '&:hover': {
        backgroundColor: '#032541',
        color: 'white'
    }
  }
  const columnCentered = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }

   const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileUpload = (e) => {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        const selectedFile = e.dataTransfer ? e.dataTransfer.files[0].name : e.target.files[0].name;
        setFile(file);
        setSelectedFile(selectedFile);
    };

    const handleClearFile = () => {
        setFile(null);
        setSelectedFile(null);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFileUpload(e);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append("business-customers", file);
        //formData.append('bankName', selectedBankName);
       
        console.log(formData,'formDataformData')

   
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${baseUrl}/api/uploadCustomer`);
        xhr.setRequestHeader("X-Authorization", localStorage.getItem('X-Authorization'));

        // track upload progress
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = event.loaded / event.total * 100;
                setUploadProgress(percentComplete);
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              const responseData =   JSON.parse(xhr.response);
              if(responseData?.data?.length > 0 && xhr.status >= 400){
                downloadDuplicateCsv(responseData?.data)
              }      
              console.log(xhr.status,'xhr.statusxhr.statusxhr.status')
                if (xhr.status === 201) {
                    setSuccessShow({ state: true, message: 'File Uploaded successfully' })
                    setLoading(false)
                    setTimeout(() => {
                        //navigate(-1)
                        window.location.reload()
                       //setShowUpload(false)
                      }, 3000)
                    // const response = JSON.parse(xhr.response);
                } else if (xhr.status >= 400) {
                    console.error("Request failed with status: " + xhr.status);
                    const response = JSON.parse(xhr.response);
                    setErrorShow({ state: true, message: response.message })
                    setLoading(false)
                }
            }
        };
        xhr.send(formData);
    }
    const downloadDuplicateCsv = (data = []) => {

        const csvHeaders = [
            {label: "CustomerType", key: "CustomerType" },
            {label: "FirstName", key: "FirstName" },
            {label: "LastName", key: "LastName" },
            {label: "Address", key: "Address" },
            {label: "Phone", key: "Phone" },
            {label: "Email", key: "Email" },
            {label: "PaymentType", key: "PaymentType" },
            {label: "Amount", key: "Amount" },
            {label: "Limit", key: "Limit" },
        ]

        const csvData = data?.map((trans) => {
            return {
                "CustomerType":trans?.customerType,
                "FirstName":trans?.firstName,
                "LastName": trans?.lastName,
                "Address":trans?.customerAddress,
                "Email":trans?.email,
                "Phone": trans?.phone,
                "PaymentType": trans?.paymantType,
                "Amount": trans?.amountReceived,
                "Limit": trans.limit

            }
        })

        const csvExports = {
            data: csvData,
            headers: csvHeaders,
            filename: `Duplicate customers file - ${new Date().toDateString()}.csv`,
          };

          const blob = new Blob([CSVData(csvExports)], { type: "text/csv;charset=utf-8;" });

          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(blob);

          link.download = csvExports.filename;

          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);

    }

  const CSVData = (exports) => {
      const rows = exports.data.map((row) => exports.headers.map((header) => row[header.key]));
      const header = exports.headers.map((header) => header.label);
      const csv = [header, ...rows].join("\r\n");
      console.log('csv', csv);
      return csv;
  };


  useEffect(() => {
  }, [dateFrom, dateTo]);

  const [customerSearchValue, setInputValue] = useState("")

  return (
    <div style={customerstyles.universalStyle}>

      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <Grid container direction={"column"}>
        <Grid item>
          <Typography fontFamily={'Poppins'} style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>Customer Accounts</Typography>
        </Grid>
        <Grid item mt={1}>
          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
            <Grid item>
              <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            
            {!showUpload && (
              <Grid item>
                <Button 
                  sx={{ marginRight: '6px' }} 
                  onClick={() => setShowUpload(true)} 
                  // startIcon={<CloudUploadIcon />} 
                  style={customerstyles.addCustomer}
                >
                  Upload Customers
                </Button>
                <Button 
                  onClick={() => navigate(`/createcustomer/new`)} 
                  startIcon={<Add />} 
                  style={customerstyles.addCustomer}
                >
                  Add Customer
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {!showUpload && (
      <Grid container style={customerstyles.gridMargin}>
        <Grid item width={"100%"}>
          <TabContext value={customersTab}>
            <AntTabs onChange={customersTabChange}>
              <AntTab label="Customers" value="Customers" />
              <AntTab label="Customer Approval" value="Approval" />
              <AntTab label="Suspended Customer" value="Suspended" />
            </AntTabs>
            <TabPanel value={"Customers"}>
              <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                <Grid item style={customerstyles.searchDateFilter}  >
                  <SearchFilter placeholder={'Search customer'} setInputValue={setInputValue} />
                  <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                </Grid>
                <Grid item>
                  <Exports  exportData={exportData} activeTabs={customersTab} csvExport={csvExport} />
                </Grid>
              </Grid>
              <Allcustomers setExportData={setExportData} setCsvExport={setCsvExport} customerSearchValue={customerSearchValue} />
            </TabPanel>

            {/* suspended! */}
            <TabPanel value={"Approval"}>
              <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                <Grid item style={customerstyles.searchDateFilter}  >
                  <SearchFilter placeholder={'Search customer'} setInputValue={setInputValue} />
                  <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                </Grid>
                <Grid item>
                  <Exports exportData={exportData} activeTabs={customersTab} csvExport={csvExport} />
                </Grid>
              </Grid>
              <CustomerAproval setExportData={setExportData} setCsvExport={setCsvExport} customerSearchValue={customerSearchValue} />
            </TabPanel>

            <TabPanel value={"Suspended"}>
              <Grid container direction={"row"} mb={2} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                <Grid item style={customerstyles.searchDateFilter}  >
                  <SearchFilter placeholder={'Search customer'} setInputValue={setInputValue} />
                  <CustomDate setDateFrom={setDateFrom} setDateTo={setDateTo} />
                </Grid>
                <Grid item>
                  <Exports exportData={exportData} activeTabs={customersTab} csvExport={csvExport} />
                </Grid>
              </Grid>
              <SuspendedCustomer setExportData={setExportData} setCsvExport={setCsvExport} customerSearchValue={customerSearchValue} />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      )}
        {showUpload && (
      <Grid mt={2} item width={'50%'} direction={'column'} display={'flex'}>
                    {Loading ?
                        <><LoadingButton
                            sx={{ width: "407px", backgroundColor: "#34A353", color: "white", "&:hover": { backgroundColor: "#34A353", color: "white", }, }}
                            endIcon={<SendIcon />}
                            loading={true}
                            loadingPosition="end"
                            variant="contained"
                        >
                            <span>Awaiting </span>
                        </LoadingButton>
                        </> :
                        <>
                            <Box sx={areaStyles}>
                                <Box sx={{
                                    display: "flex",
                                    alignSelf: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "42.375rem",
                                    height: "18.269rem",
                                    padding: "20px 170.5px 46.3px 159.5px",
                                    border: "dashed 2px #c2cdda"
                                }}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <img src={icon} alt="Upload" />
                                    </Box>
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <Typography sx={{ fontSize: "1rem", color: "#032541" }}>
                                            Drag and drop your CSV file here
                                        </Typography>
                                    </Box>
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <Typography sx={{ fontSize: "0.875rem", color: "#666666" }}>
                                            or
                                        </Typography>
                                    </Box>
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={handleFileUpload}
                                        style={{ display: "none" }}
                                        ref={fileInputRef}
                                    />
                                    <Box sx={{ alignSelf: "center", marginY: 1 }}>
                                        <Button sx={buttonStyling} onClick={() => fileInputRef.current.click()}>
                                            Browse Files
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{display:'flex' , my:2, justifyContent:'center'}}>
                                <a href={csvTemplate} download>
                                    <Button sx={{ color: "#032541", fontSize: "16px" }} startIcon={<FileDownloadOutlinedIcon />}>
                                        Download CSV Template
                                    </Button>
                                </a>
                            </Box>
                            {
                                selectedFile ?
                                    <Box component="div" id="file-identifier"
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            border: "solid 1px #e1e1e1",
                                            borderRadius: "0.25rem",
                                            marginTop: "10px"
                                        }}>
                                        <Box component="div" sx={{ display: "flex", marginX: 1, padding: 1, }}>
                                            <Box component="div" sx={{ ...columnCentered }}>
                                                <CloseOutlinedIcon onClick={handleClearFile} sx={{ color: "#dc3545", cursor: "pointer" }} />
                                            </Box>
                                            <Box component="div" sx={{ ...columnCentered }}>
                                                <InsertDriveFileOutlinedIcon sx={{ color: "#032541" }} />
                                            </Box>
                                            <Box component="div" sx={{ ...columnCentered, marginLeft: 1 }}>
                                                <Typography sx={{ color: "#666666", fontSize: "0.875rem" }}>
                                                    {selectedFile}
                                                </Typography>
                                            </Box>
                                            <Box component="div" sx={{ marginLeft: 3.5, marginRight: 4, ...columnCentered }}>
                                                <progress value={uploadProgress} max="100" />
                                            </Box>
                                            <Box component="div" sx={{ ...columnCentered, marginLeft: 'auto', }}>
                                                <Button onClick={handleSubmit} style={{ color: "#fff", width: '80px', backgroundColor: '#032541', opacity: 1, fontSize: "0.875rem", fontWeight: 700 }}>
                                                    Upload
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    : null
                            }
                        </>
                    }
                </Grid>
        )}
    </div>
  );
};

export default Customerlist;
